@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Sono:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Silkscreen:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Silkscreen", cursive;
  font-weight: 400;
}

code {
  font-family: "Silkscreen";
}

.nav {
  position: sticky;
  top: 0;
}
